<template>
  <AppRow only-owner>
    <template v-slot>
      <v-col>
        <ApolloQuery :query="require('../graphql/queries/findUsers.gql')">
          <template v-slot="{ result: { error: usersError, data: usersData }, isLoading: usersIsLoading }">
            <!-- Error -->
            <AppNotification
              v-if="usersError"
              :message="usersError.message"
            />

            <!-- Result -->
            <v-card
              v-else-if="usersData || usersIsLoading === 1"
              :loading="usersIsLoading === 1"
              class="pa-4"
              flat
            >
              <template v-slot:progress>
                <v-progress-linear indeterminate />
              </template>

              <v-row v-if="usersData">
                <v-col
                  cols="12"
                  md="3"
                  sm="6"
                >
                  <v-text-field
                    v-model="message"
                    background-color="#464646"
                    class="rounded-lg"
                    flat
                    hide-details="auto"
                    label="Сообщение"
                    solo
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                  sm="6"
                >
                  <v-text-field
                    v-model="from"
                    background-color="#464646"
                    class="rounded-lg"
                    flat
                    hide-details="auto"
                    label="От"
                    solo
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                  sm="6"
                >
                  <v-autocomplete
                    v-model="receivers"
                    :items="usersData.findUsers ? usersData.findUsers.users : []"
                    background-color="#464646"
                    class="rounded-lg"
                    clearable
                    flat
                    hide-details="auto"
                    item-text="username"
                    item-value="id"
                    label="Получатели (по умолч. все)"
                    multiple
                    solo
                  >
                    <template v-slot:selection="{ attrs, item, select, selected }">
                      <v-chip
                        v-bind="attrs"
                        :input-value="selected"
                        close
                        @click="select"
                        @click:close="remove(item)"
                      >
                        <v-avatar left>
                          <v-img :src="item.img" />
                        </v-avatar>
                        {{ item.username }}
                      </v-chip>
                    </template>

                    <template v-slot:item="{ item }">
                      <v-list-item-avatar>
                        <img :src="item.img">
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>{{ item.username }}</v-list-item-title>
                        <v-list-item-subtitle>ID: {{ item.id }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                  sm="6"
                >
                  <ApolloMutation
                    :mutation="require('../graphql/mutations/sendNotification.gql')"
                    :variables="{
                      receivers,
                      from,
                      message,
                    }"
                    @done="onNotification"
                  >
                    <template v-slot="{ mutate, loading, error: sendError }">
                      <v-btn
                        :loading="loading"
                        block
                        class="text-none rounded-lg"
                        color="primary"
                        depressed
                        height="48"
                        x-large
                        @click="mutate()"
                      >
                        Отправить
                      </v-btn>
                      <AppNotification
                        v-if="sendError"
                        :icon-disabled="true"
                        :message="sendError.message"
                      />
                    </template>
                  </ApolloMutation>
                </v-col>
              </v-row>
            </v-card>

            <!-- No result -->
            <AppNotification
              v-else
              message="Нет результата :("
            />
          </template>
        </ApolloQuery>
      </v-col>
    </template>
  </AppRow>
</template>

<script>
import AppRow from '@/components/AppRow.vue';
import AppNotification from '@/components/AppNotification.vue';

export default {
  components: {
    AppRow,
    AppNotification,
  },
  data() {
    return {
      message: '',
      from: '',
      receivers: [],
    };
  },
  methods: {
    remove(item) {
      const index = this.receivers.indexOf(item.id);
      if (index >= 0) this.receivers.splice(index, 1);
    },
    onNotification() {
      this.message = '';
      this.from = '';
      this.receivers = [];
    },
  },
};
</script>
