var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppRow',{attrs:{"only-owner":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('v-col',[_c('ApolloQuery',{attrs:{"query":require('../graphql/queries/findUsers.gql')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_result = ref.result;
var usersError = ref_result.error;
var usersData = ref_result.data;
var usersIsLoading = ref.isLoading;
return [(usersError)?_c('AppNotification',{attrs:{"message":usersError.message}}):(usersData || usersIsLoading === 1)?_c('v-card',{staticClass:"pa-4",attrs:{"loading":usersIsLoading === 1,"flat":""},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c('v-progress-linear',{attrs:{"indeterminate":""}})]},proxy:true}],null,true)},[(usersData)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3","sm":"6"}},[_c('v-text-field',{staticClass:"rounded-lg",attrs:{"background-color":"#464646","flat":"","hide-details":"auto","label":"Сообщение","solo":""},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3","sm":"6"}},[_c('v-text-field',{staticClass:"rounded-lg",attrs:{"background-color":"#464646","flat":"","hide-details":"auto","label":"От","solo":""},model:{value:(_vm.from),callback:function ($$v) {_vm.from=$$v},expression:"from"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3","sm":"6"}},[_c('v-autocomplete',{staticClass:"rounded-lg",attrs:{"items":usersData.findUsers ? usersData.findUsers.users : [],"background-color":"#464646","clearable":"","flat":"","hide-details":"auto","item-text":"username","item-value":"id","label":"Получатели (по умолч. все)","multiple":"","solo":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var select = ref.select;
var selected = ref.selected;
return [_c('v-chip',_vm._b({attrs:{"input-value":selected,"close":""},on:{"click":select,"click:close":function($event){return _vm.remove(item)}}},'v-chip',attrs,false),[_c('v-avatar',{attrs:{"left":""}},[_c('v-img',{attrs:{"src":item.img}})],1),_vm._v(" "+_vm._s(item.username)+" ")],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-avatar',[_c('img',{attrs:{"src":item.img}})]),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.username))]),_c('v-list-item-subtitle',[_vm._v("ID: "+_vm._s(item.id))])],1)]}}],null,true),model:{value:(_vm.receivers),callback:function ($$v) {_vm.receivers=$$v},expression:"receivers"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3","sm":"6"}},[_c('ApolloMutation',{attrs:{"mutation":require('../graphql/mutations/sendNotification.gql'),"variables":{
                    receivers: _vm.receivers,
                    from: _vm.from,
                    message: _vm.message,
                  }},on:{"done":_vm.onNotification},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var mutate = ref.mutate;
                  var loading = ref.loading;
                  var sendError = ref.error;
return [_c('v-btn',{staticClass:"text-none rounded-lg",attrs:{"loading":loading,"block":"","color":"primary","depressed":"","height":"48","x-large":""},on:{"click":function($event){return mutate()}}},[_vm._v(" Отправить ")]),(sendError)?_c('AppNotification',{attrs:{"icon-disabled":true,"message":sendError.message}}):_vm._e()]}}],null,true)})],1)],1):_vm._e()],1):_c('AppNotification',{attrs:{"message":"Нет результата :("}})]}}])})],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }